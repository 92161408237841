import React from 'react'
import styled from 'styled-components'
import { MdArrowForward, MdLink } from "react-icons/md";

const ActionButton = styled.button`
  background-color: ${(props) => props.theme.theme.colors.blue};
  border: none;
  color: ${(props) => props.theme.theme.colors.white};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;

  @media (max-width: 1200px) {
    margin: 0 0 36px 0;
  }

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }
`

export default (props) => {

  return (
    <ActionButton onClick={props.onClick}>
      {props.text}
      {props.icon}
    </ActionButton>

  )
}
