import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { format, distanceInWords, differenceInDays } from "date-fns";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../../lib/helpers";
import Container from "../../components/container";
import GraphQLErrorList from "../../components/graphql-error-list";
import SEO from "../../components/seo";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { ContainerFullWidth, ContainerBodyWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, PanelLabel } from "../../containers";
import Hero from "../../components/hero";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import NumberFormat from "react-number-format";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { MdHelpOutline, MdHelp, MdCheck, MdWarning, MdInfo, MdInfoOutline, MdArrowForward } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import { GumroadAd } from '../../components/GumroadAd'
import ActionButton from "../../components/ActionButton";

const H1 = styled.h1`
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

const H2 = styled.h2`
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

const CalculatorGrid = styled.div`
  margin: 0 auto;
  padding: 24px;
  max-width: 1400px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  background-color: ${(props) => props.theme.theme.bg.primary};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: grid;
  /* grid-template-columns: 1fr; */
  grid-template-columns: 1fr;
  grid-gap: 0px;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 0;
    border: none;
    border-radius: 0px;
    margin: 0 0 0 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`;

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0px 0 0 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }

`;

const NeutralTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0px 0 0 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.green};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`;

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    margin: 0 0 0 4px;
  }
`;

const GroupLabel = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`;

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`;

const TooltipPanel = styled.div`
  background-color: ${(props) => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`;

const InputSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 12px 12px 12px;
  border-left: 2px solid;
  /* border-radius: 8px; */
  margin: 0 0 24px 0;
`;

const InputSectionGreen = styled(InputSection)`
  border-color: ${(props) => props.theme.theme.colors.green};

  .GroupLabel {
    color: ${(props) => props.theme.theme.colors.green};
  }
`;

const InputSectionBlue = styled(InputSection)`
  border-color: ${(props) => props.theme.theme.colors.blue};

  .GroupLabel {
    color: ${(props) => props.theme.theme.colors.blue};
  }
`;

const InputGroup = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */

  @media (max-width: 500px) {
    padding: 24px 12px 0 12px;
  }
`;

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${(props) => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
`;

const AllocationGroup = styled.div`
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 12px 12px 0;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  color: ${(props) => props.theme.theme.colors.green};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const AllocationGroupPurple = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentPurple};
  border: 1px solid ${(props) => props.theme.theme.colors.purple};
  color: ${(props) => props.theme.theme.colors.purple};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.purple};
  }
`;

const AllocationGroupOrange = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentOrange};
  border: 1px solid ${(props) => props.theme.theme.colors.orange};
  color: ${(props) => props.theme.theme.colors.orange};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.orange};
  }
`;

const AllocationGroupBlue = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  border: 1px solid ${(props) => props.theme.theme.colors.blue};
  color: ${(props) => props.theme.theme.colors.blue};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.blue};
  }
`;

const AllocationGroupBlack = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.colors.black};
  color: ${(props) => props.theme.theme.colors.black};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.black};
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRow = styled.div`
  display: grid;
  grid-template-columns: 90px 100px 60px;
  grid-gap: 18px;
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationMessage = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  width: 225px;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* flex-wrap: wrap; */
  margin: 12px 0 0 0;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 6px;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0 12px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* padding: 4px 0; */

  p {
    font-size: 1rem;
    margin: 8px 0;
  }

  @media (max-width: 500px) {
  }
`;

const NotePanel = styled(AllocationGroup)`
  color: ${(props) => props.theme.theme.text.tertiary};
  padding: 0px;
  display: grid;
  align-items: center;
  grid-template-columns: 25px 1fr;
  margin: 0px;

  svg {
    margin: 0 4px 0 0;
  }
`;

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const CustomTooltip = (props) => {
  const { active } = props;

  if (active) {
    const { payload, label } = props;
    // console.log(payload);
    // console.log(label);

    return (
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Age: </strong>
          {payload[0].payload.age}
        </CardText400>
        <CardText400>
          <strong>Net Worth: </strong>
          <NumberFormat
            displayType={"text"}
            value={payload[0].payload.actualNetWorth.toFixed(0)}
            thousandSeparator
            prefix="$"
          />
        </CardText400>
        {/* <CardText400>
          <strong>Coast FIRE number: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.theoreticalNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400> */}
        <CardText400>
          <strong>FIRE number: </strong>
          <NumberFormat
            displayType={"text"}
            value={payload[0].payload.fireNumber.toFixed(0)}
            thousandSeparator
            prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    );
  }
  return null;
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FireWindfallImpactCalc = () => {

  const [currentAge, setCurrentAge] = useState(30);
  const [currentTakeHomePay, setCurrentTakeHomePay] = useState(60000);
  const [currentSalary, setCurrentSalary] = useState(85000);
  const [currentAnnualSpending, setCurrentAnnualSpending] = useState(30000);
  const [currentNetWorth, setCurrentNetWorth] = useState(100000);
  const [graphEndAge, setGraphEndAge] = useState(67);
  const [annualSpend, setAnnualSpend] = useState(30000);
  const [investmentRate, setInvestmentRate] = useState(10);
  const [stocksRate, setStocksRate] = useState(8);
  const [stocksAllocation, setStocksAllocation] = useState(90);
  const [bondsRate, setBondsRate] = useState(5);
  const [bondsAllocation, setBondsAllocation] = useState(0);
  const [cashRate, setCashRate] = useState(0.5);
  const [cashAllocation, setCashAllocation] = useState(10);
  const [miscRate, setMiscRate] = useState(5);
  const [miscAllocation, setMiscAllocation] = useState(0);
  const [sideIncomeMonthly, setSideIncomeMonthly] = useState(0);
  const [sideIncomeAgeRange, setSideIncomeAgeRange] = useState([50, 60]);
  const [companyMatch, setCompanyMatch] = useState(0);
  const [incomeGrowthRate, setIncomeGrowthRate] = useState(3);

  const [inflationRate, setInflationRate] = useState(3);
  const [SWR, setSWR] = useState(4);
  const [annualCompoundingPeriods, setAnnualCompoundingPeriods] = useState(365);
  const [annualPaymentPeriods, setAnnualPaymentPeriods] = useState(365);
  const [windfallValue, setWindfallValue] = useState(50000);
  const [initialCompoundingData, setInitialCompoundingData] = useState(0);
  const [modifiedCompoundingData, setModifiedCompoundingData] = useState(0);

  const payment = ( currentTakeHomePay - currentAnnualSpending ) / 365

  const netGrowthRate = investmentRate - inflationRate

  const rate =
    Math.pow(
      1 + ( ( netGrowthRate/100 ) / annualCompoundingPeriods ),
      annualCompoundingPeriods / annualPaymentPeriods
    ) - 1;

    // console.log(`rate: ${rate}`)

  const fireNumber = annualSpend / (0.01 * SWR);

  let fireAge = 0;

  const calcInitialCompoundingData = () => {
    let initialCompoundingData = [
      {
        month: 0,
        localFutureValue: currentNetWorth
        // interestCrypto: i ? localCryptoFutureValue - compoundingData[i-1].localCryptoFutureValue - payment : 0
      }
    ]
    for(let i = 1; initialCompoundingData[i-1].localFutureValue <=  fireNumber; i++){
      const localNper = i
      const localFutureValue = (currentNetWorth) * Math.pow(1+rate, localNper) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )

      initialCompoundingData.push({
        month: i,
        localFutureValue: localFutureValue
        // interestCrypto: i ? localCryptoFutureValue - compoundingData[i-1].localCryptoFutureValue - payment : 0
      })
    }
    return initialCompoundingData
  }

  const calcModifiedCompoundingData = () => {
    let modifiedCompoundingData = [
      {
        month: 0,
        localFutureValue: parseFloat(currentNetWorth) + parseFloat(windfallValue)
        // interestCrypto: i ? localCryptoFutureValue - compoundingData[i-1].localCryptoFutureValue - payment : 0
      }
    ]

    for(let i = 1; modifiedCompoundingData[i-1].localFutureValue <=  fireNumber; i++){
      const localNper = i
      const localFutureValue = (parseFloat(currentNetWorth) + parseFloat(windfallValue)) * Math.pow(1+rate, localNper) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )

      modifiedCompoundingData.push({
        month: i,
        localFutureValue: localFutureValue
        // interestCrypto: i ? localCryptoFutureValue - compoundingData[i-1].localCryptoFutureValue - payment : 0
      })
    }
    return modifiedCompoundingData
  }



  const calcNetWorthData = () => {
    let netWorthData = [];

    for (let i = currentAge; i <= graphEndAge; i++) {

      const time = i - currentAge;
      const localNper = annualPaymentPeriods * time;

      if (i == currentAge) {
        netWorthData.push({
          age: i,
          year: i - currentAge,
          actualNetWorth: parseFloat(currentNetWorth),
          annualIncome: "N/A",
          annualSavings: "N/A",
          investmentGains: "N/A",
          // theoreticalNetWorth: currentNetWorth * ( 1 + rate ) + (currentTakeHomePay - setCurrentAnnualSpending) + 19500 * (companyMatch / 100),
          fireNumber: fireNumber,
          continuousGrowthNetWorth: currentNetWorth,
        });
      } else {
        if (i == currentAge + 1) {
          netWorthData.push({
            age: i,
            year: i - currentAge,
            actualNetWorth:
              currentNetWorth * (1 + rate) +
              (currentTakeHomePay - currentAnnualSpending) +
              currentSalary * (companyMatch / 100),
            annualIncome: parseFloat(currentTakeHomePay),
            annualSavings: currentTakeHomePay - currentAnnualSpending,
            investmentGains: currentNetWorth * rate,
            // theoreticalNetWorth: currentNetWorth * ( 1 + rate ) + (currentTakeHomePay - setCurrentAnnualSpending) + 19500 * (companyMatch / 100),
            fireNumber: fireNumber,
            continuousGrowthNetWorth:
              currentNetWorth * (1 + rate) +
              (currentTakeHomePay - currentAnnualSpending) +
              currentSalary * (companyMatch / 100),
          });
        } else {
          const prev = i - 1 - currentAge;

          const instanceTakeHomePay =
            currentTakeHomePay *
            Math.pow(1 + 0.01 * (incomeGrowthRate - inflationRate), i - currentAge - 1);
          // console.log(instanceTakeHomePay);

          const instancePreTaxPay =
            currentSalary *
            Math.pow(1 + 0.01 * (incomeGrowthRate - inflationRate), i - currentAge - 1);

          // const actualNetWorthDataPoint = netWorthData[prev].actualNetWorth * ( 1 + rate ) + ( instanceTakeHomePay - currentAnnualSpending ) + 19500 * (companyMatch / 100)

          const continuousGrowthNetWorth =
            netWorthData[prev].actualNetWorth * (1 + rate) +
            (instanceTakeHomePay - currentAnnualSpending) +
            instancePreTaxPay * (companyMatch / 100);

          let actualNetWorthDataPoint = continuousGrowthNetWorth;

          // if(netWorthData[prev].actualNetWorth >= fireNumber){
          //   actualNetWorthDataPoint = 0
          // } else {
          //   actualNetWorthDataPoint = continuousGrowthNetWorth
          // }

          netWorthData.push({
            age: i,
            year: i - currentAge,
            actualNetWorth: actualNetWorthDataPoint,
            annualIncome: instanceTakeHomePay,
            annualSavings: instanceTakeHomePay - currentAnnualSpending,
            investmentGains: netWorthData[prev].actualNetWorth * rate,
            // theoreticalNetWorth: coastNumber * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)),
            fireNumber: fireNumber,
            continuousGrowthNetWorth: continuousGrowthNetWorth,
          });

          if (!fireAge && continuousGrowthNetWorth >= fireNumber) {
            fireAge = i - 1;
          }

          // if( actualNetWorthDataPoint < fireNumber){
          //   graphEndAge = i + 5
          //   console.log(graphEndAge)
          // }
        }
      }

      // netWorthData.push({
      //   age: i,
      //   actualNetWorth: currentNetWorth * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate ) ,
      //   theoreticalNetWorth: coastNumber * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)),
      //   fireNumber: fireNumber
      // })
    }
    return netWorthData;
  };

  const netWorthData = calcNetWorthData();

  // const initialCompoundingData = calcInitialCompoundingData();
  // let initialCompoundingData =  0
  // let modifiedCompoundingData = 0


  // const modifiedCompoundingData = calcModifiedCompoundingData();
  // const fireTimelineImpactDays = (modifiedCompoundingData.length - initialCompoundingData.length).toFixed(0)

  const timelineToText = ( num ) => {
    let days = num
    let years = 0
    let months = 0

    if( days >= 365){
      years = Math.floor(days/365)
      days = days % 365
    }

    if( days >= 30){
      // console.log(days)
      months = Math.floor(days/30)
      days = days % 30
      // console.log(days)
    }

    return(
      `${years ? `${years} ${years > 1 ? ` years, ` : ` year, ` } ` : ''}
      ${months ? `${months} ${months > 1 ? ` months, ` : ` month, `} ` : ''} ${days ? `${days} ${days > 1 ? ` days` : ` day`}` : ''}`
    )
  }

  const runCalc = (e) => {
    e.preventDefault();
    // console.log('calc')
    setInitialCompoundingData( calcInitialCompoundingData() )
    setModifiedCompoundingData( calcModifiedCompoundingData() )
    console.log(initialCompoundingData)
    console.log(modifiedCompoundingData)
  }


  console.log(windfallValue);

  // console.log(initialCompoundingData)
  // console.log(modifiedCompoundingData)

  return (
    <Layout>
      <SEO
        title={"Windfall Impact on FIRE Timeline Calculator - Financial Independence Retire Early"}
        description={
          "Calculate how much a windall impacts your FIRE timeline"
        }
      />
      <CustomReactTooltip />

      <ContainerBodyWidth>
        <CalculatorGrid>
          <form>
            <InputGroup>
              <FlexInput style={{ width: "240px" }}>
                <InputItem>
                  <ThemedTextarea
                    id="outlined-name"
                    label="Annual Take-Home Pay"
                    value={currentTakeHomePay}
                    onChange={(event) => setCurrentTakeHomePay(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is your current annual take-home pay after all taxes.
                      This DOES include any contributions you make to retirement accounts like a 401k or HSA.`}
                  />
                </InputItem>
              </FlexInput>
              <FlexInput style={{ width: "240px" }}>
                <InputItem>
                  <ThemedTextarea
                    id="outlined-name"
                    label="Current Annual Spending"
                    value={currentAnnualSpending}
                    onChange={(event) => setCurrentAnnualSpending(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <MdHelp size="1.2rem" data-tip={`This is your current annual spending.`} />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "240px" }}>
                <InputItem>
                  <ThemedTextarea
                    id="outlined-name"
                    label="Retirement Annual Spending"
                    value={annualSpend}
                    onChange={(event) => setAnnualSpend(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is your estimated annual spending in retirement.
                      Consider some spending areas like health insurance will increase while others like your mortgage may decrease once you pay off your home.`}
                  />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "240px" }}>
                <InputItem>
                  <ThemedTextarea
                    id="outlined-name"
                    label="Current Investments"
                    value={currentNetWorth}
                    onChange={(event) => setCurrentNetWorth(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the current value of your investments in the stock market,
                      including retirement accounts like 401k or IRA and brokerage accounts.`}
                  />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "240px" }}>
                <InputItem>
                  <ThemedTextarea
                    id="outlined-name"
                    label="Windfall value (after-tax)"
                    value={windfallValue}
                    onChange={(event) => setWindfallValue(event.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the post-tax value of a windfall that you receive in the present day and immediately invest (into low-cost index funds).`}
                  />
                </InputItem>
              </FlexInput>

              {/* <FlexInput style={{ width: "150px" }}>
                <InputItem>
                  <ThemedTextarea
                    id="outlined-name"
                    label="Graph End Age"
                    value={graphEndAge}
                    onChange={(event) => setGraphEndAge(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                  <MdHelp
                    size="1.2rem"
                    data-tip={`Adjust this value to change the end age on the graph to zoom in or out.`}
                  />
                </InputItem>
              </FlexInput> */}

              <AllocationGroupGray>
                <Label>
                  Investment Growth Rate
                  <MdHelp
                    size="1.2rem"
                    data-tip="The average return that you expect your investments to grow, not adjusted for inflation.
                      Historically, the S&P 500 has returned on average 10% annually from its inception in 1926 to 2018."
                  />
                </Label>
                <AllocationGroupRowTwoCol>
                  <SliderBlack
                    value={investmentRate}
                    aria-labelledby="discrete-slider-always"
                    step={0.5}
                    min={0}
                    max={20}
                    marks={[
                      {
                        value: 0,
                        label: "0%",
                      },
                      {
                        value: 10,
                        label: "10%",
                      },
                      {
                        value: 20,
                        label: "20%",
                      },
                    ]}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => setInvestmentRate(value)}
                  />

                  <ThemedTextarea
                    id="outlined-name"
                    label="Rate"
                    value={investmentRate}
                    onChange={(event) => setInvestmentRate(event.target.value)}
                    variant="filled"
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    size="small"
                    fullWidth
                  />
                </AllocationGroupRowTwoCol>
              </AllocationGroupGray>

              <AllocationGroupGray>
                <Label>
                  Inflation rate
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the average annual rate of inflation that you expect to experience in the future.
                        Historically, the US economy has experienced an annual average inflation rate of 3%.
                        This value is used to normalize the calculator to be in 2020 dollars.`}
                  />
                </Label>

                <AllocationGroupRowTwoCol>
                  <SliderBlack
                    value={inflationRate}
                    aria-labelledby="discrete-slider-always"
                    step={0.25}
                    min={2}
                    max={6}
                    marks={[
                      {
                        value: 2,
                        label: "2%",
                      },
                      {
                        value: 3,
                        label: "3%",
                      },
                      {
                        value: 6,
                        label: "6%",
                      },
                    ]}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => setInflationRate(value)}
                  />

                  <ThemedTextarea
                    id="outlined-name"
                    label="Rate"
                    value={inflationRate}
                    onChange={(event) => setInflationRate(event.target.value)}
                    variant="filled"
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    size="small"
                    fullWidth
                  />
                </AllocationGroupRowTwoCol>
              </AllocationGroupGray>

              <AllocationGroupGray>
                <Label>
                  Safe Withdrawal Rate
                  <MdHelp
                    size="1.2rem"
                    data-tip="Safe withdrawal rate (SWR) is the percentage of your net worth that you withdraw each year in retirement.
                        4% is widely considered as the recommended SWR for retirement planning."
                  />
                </Label>
                <AllocationGroupRowTwoCol>
                  <SliderBlack
                    value={SWR}
                    aria-labelledby="discrete-slider-always"
                    step={0.5}
                    min={2}
                    max={6}
                    marks={[
                      {
                        value: 2,
                        label: "2%",
                      },
                      {
                        value: 4,
                        label: "4%",
                      },
                      {
                        value: 6,
                        label: "6%",
                      },
                    ]}
                    valueLabelDisplay="auto"
                    onChange={(event, value) => setSWR(value)}
                  />

                  <ThemedTextarea
                    id="outlined-name"
                    label="Rate"
                    value={SWR}
                    onChange={(event) => setSWR(event.target.value)}
                    variant="filled"
                    InputProps={{
                      inputComponent: NumberFormatPercentage,
                    }}
                    size="small"
                    fullWidth
                  />
                </AllocationGroupRowTwoCol>
              </AllocationGroupGray>

            </InputGroup>

            <InputGroup>
              <ActionButton 
                  onClick={(e) => runCalc(e)} 
                  text={"Calculate"} 
                  icon={<MdArrowForward size="20px" />}
                  />
            </InputGroup>

              { initialCompoundingData ?
                <ResultsPanel>
                  <ResultsGroup>
                    <span className="label">🔥 Your FIRE number: </span>
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={fireNumber.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </ResultsGroup>

                  <ResultsGroup>
                    {/* <span className="label">Purchase Impact on FIRE timeline: </span> */}
                    <p>
                      <span>⏰ This windfall <strong>reduces</strong> your time to FIRE by</span> <span className="result-value">{timelineToText(initialCompoundingData.length - modifiedCompoundingData.length)}</span>
                      <span>.</span>

                    </p>

                  </ResultsGroup>

                  <ResultsGroup>
                    <p>
                      <span><strong>💵 Without </strong> this windfall, you are</span> <span className="result-value"> {timelineToText(initialCompoundingData.length)}</span> <span>away from FIRE.</span>
                    </p>
                    {/* <span className="label">Time until FIRE (without purchase): </span> */}
                    {/* <span className="result-value">{timelineToText(initialCompoundingData.length)}</span> */}
                  </ResultsGroup>

                  <ResultsGroup>
                    <p>
                      <span><strong>💳 With</strong> this windfall, you are </span> <span className="result-value">{timelineToText(modifiedCompoundingData.length)}</span> <span>away from FIRE.</span>
                    </p>
                    {/* <span className="label">Time until FIRE (with purchase): </span> */}

                  </ResultsGroup>

                </ResultsPanel> : ''
              }

          </form>

          {/* <GraphDesktop>
            <ResponsiveContainer width="100%" height={500}>
              <AreaChart data={netWorthData} margin={{ top: 10, right: 0, left: 50, bottom: 30 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="age"
                  label={{ value: "Age (years)", position: "insideBottom", offset: -10 }}
                />
                <YAxis
                  tickFormatter={(tick) => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" height={-20} />
                <Area
                  type="monotone"
                  name="Net Worth"
                  dataKey="actualNetWorth"
                  stroke="#3AC732"
                  fill="#CFF0CD"
                />
                <Area
                  type="monotone"
                  name="FIRE number"
                  dataKey="fireNumber"
                  stroke="#FF1733"
                  fillOpacity={0}
                />
              </AreaChart>
            </ResponsiveContainer>
          </GraphDesktop> */}

          {/* <GraphMobile>
            <ResponsiveContainer width="100%" height={500}>
              <AreaChart data={netWorthData} margin={{ top: 10, right: 0, left: 10, bottom: 30 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="age"
                  label={{ value: "Age (years)", position: "insideBottom", offset: -10 }}
                />
                <YAxis
                  tickFormatter={(tick) => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                  }}
                  mirror
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="top" height={36} />
                <Area
                  type="monotone"
                  name="Net Worth"
                  dataKey="actualNetWorth"
                  stroke="#3AC732"
                  fill="#CFF0CD"
                />
                <Area
                  type="monotone"
                  name="FIRE number"
                  dataKey="fireNumber"
                  stroke="#FF1733"
                  fillOpacity={0}
                />
              </AreaChart>
            </ResponsiveContainer>
          </GraphMobile> */}

          <NotePanel>
            <MdInfo size="20px" />
            <p>
              Note that all values are in current dollars, adjusted for future inflation.
            </p>
          </NotePanel>

          {/* <ThemedAccordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Yearly Finances Table</AccordionSummary>
            <AccordionDetails>
              <FinancesTableWrapper>
                <FinancesTableGrid>
                  <FinancesTableRow className="labels-row">
                    <span>Age</span>
                    <span>Years Elapsed</span>
                    <span>Annual Income</span>
                    <span>Annual Savings</span>
                    <span>Net Worth</span>
                  </FinancesTableRow>

                  {netWorthData.map((dataPoint, index) => {
                    if (!index) {
                      return (
                        <FinancesTableRow>
                          <span>{dataPoint.age}</span>
                          <span>{dataPoint.year}</span>
                          <span>{dataPoint.annualIncome}</span>
                          <span>{dataPoint.annualSavings}</span>
                          <span>
                            <NumberFormat
                              displayType={"text"}
                              value={dataPoint.actualNetWorth.toFixed(0)}
                              thousandSeparator
                              prefix="$"
                            />
                          </span>
                        </FinancesTableRow>
                      );
                    } else {
                      return (
                        <FinancesTableRow>
                          <span>{dataPoint.age}</span>
                          <span>{dataPoint.year}</span>
                          <span>
                            <NumberFormat
                              displayType={"text"}
                              value={dataPoint.annualIncome.toFixed(0)}
                              thousandSeparator
                              prefix="$"
                            />
                          </span>
                          <span>
                            <NumberFormat
                              displayType={"text"}
                              value={dataPoint.annualSavings.toFixed(0)}
                              thousandSeparator
                              prefix="$"
                            />
                          </span>
                          <span>
                            <NumberFormat
                              displayType={"text"}
                              value={dataPoint.actualNetWorth.toFixed(0)}
                              thousandSeparator
                              prefix="$"
                            />
                          </span>
                        </FinancesTableRow>
                      );
                    }
                  })}
                </FinancesTableGrid>
              </FinancesTableWrapper>
            </AccordionDetails>
          </ThemedAccordion> */}
        </CalculatorGrid>
      </ContainerBodyWidth>

    </Layout>
  );
};

export default FireWindfallImpactCalc;
