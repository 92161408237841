import React from 'react'
import styled from 'styled-components'
import Accordion from "@material-ui/core/Accordion";

export const GraphDesktop = styled.div`
  display: block;

  @media (max-width: 600px) {
    display: none;
  }

  .recharts-text, .recharts-cartesian-axis-tick-value {
    fill: ${props => props.theme.theme.text.primary} !important;
  }
`

export const GraphMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }

  .recharts-text, .recharts-cartesian-axis-tick-value {
    fill: ${props => props.theme.theme.text.primary} !important;
  }
`

export const AxisLabel = styled.h4`
  text-align: center;
  margin: 0 0 12px 0;
`

export const ThemedAccordion = styled(Accordion)`
  &.MuiPaper-root {
    background-color: ${props => props.theme.theme.bg.primary};
  }

  .MuiAccordionSummary-content, .Mui-expanded {
    color: ${props => props.theme.theme.text.primary};
  }

  .MuiIconButton-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiAccordion-root:before {
    background-color: ${props => props.theme.theme.formBorder.primary};
  }
`

export const FinancesTableWrapper = styled.div`
  overflow-x: scroll;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  color: ${props => props.theme.theme.text.primary};

  @media (max-width: 500px) {
    max-width: 90vw;
  }
`;

export const FinancesTableGrid = styled.div`
  display: grid;
  margin: 0 auto;
`;

export const FinancesTableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 150px);
  justify-items: center;
  padding: 4px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(5, 85px);
  }
`;

export const FinancesTableRowREI = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 150px);
  justify-items: center;
  padding: 4px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(9, 85px);
  }
`;